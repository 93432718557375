.export-excel-button {
    background: none;
    border: none;
    color: blue;
    /* Customize text color */
    /* text-decoration: underline; */
    /* Add underline effect */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    font-family: inherit;
    /* Inherit font family from parent */
    font-size: inherit;
    /* Inherit font size from parent */
    padding: 0;
    /* Remove padding */
    margin: 0;
    /* Remove margin */
}