.nav-sm{
    display: none;
}
@media ( width < 992px){
    .nav-sm{
    width: 95% !important;
    display: block;
    position: sticky;
    top: 12px;
    z-index: 1199;
    /* margin-left: 16px;
    margin-right: 16px; */
    margin-top: 5px;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: #344767;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4);

}
.css-nkchhw {
    margin-top: 30px;

}
.css-1b2renv-MuiPaper-root-MuiDrawer-paper {
    height: calc(100vh - 0.5rem) !important;
    /* width: 23rem !important; */
    overflow-x: hidden !important;
}
.link_item{
    font-size: 13.5px !important;
    width: 98%;
    padding: 10px 10px;
}
.toggle-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-toggle-img{
    width: 70px;
    height: 70px;
    img{
        width: 100%;
        height: auto;
    }
}
.toggle-button{
        padding: 0.4rem 0.6rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0.0625rem solid white;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease-in-out;
   
}


}
@media (width < 546px){
    .css-nkchhw {
            padding: 5px !important;
    }
}