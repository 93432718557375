

.table{
    /* background-image: url('../../coat-removebg-preview.png'); */
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: fixed;
    color: whitesmoke;
    backdrop-filter: blur(8px);
    /* width: 1000px; */
    height: 80px;
    align-items: center;
}
/* td{
    background-color: transparent;
  }

td:hover{
    background-color: red;
  } */
   @media (width < 546px){
    .rst__rowLabel{
      font-size: 14px;
   }
  .css-1nu8y0r-MuiTypography-root{
    font-size: 1.1rem !important;
  }
  .sm-btn{
    font-size: 12px !important;
    margin-inline: 3px !important;
    /* margin-bottom: 10px !important; */
  }
  .sm-btn-1{
    margin-top: -3px;
  }
  }