/* src/CustomBarChart.css */
.custom-legend {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.custom-legend div {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.custom-legend span {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
}

.green {
  background-color: #008000;
}

.yellow {
  background-color: #FFD700;
}

.red {
  background-color: #FF0000;
}
