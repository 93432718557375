/* src/FlowOfFunds.css */

.flow-of-funds {
  text-align: center;
  margin-top: 50px;
}

.flow-of-funds h1 {
  margin-bottom: 30px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 20px auto;
  width: 80%;
  max-width: 600px;
  color: white;
  font-size: 1.2em;
  position: relative; /* For positioning pseudo-elements */
}

.incoming {
  background-color: green;
}

.outgoing {
  background-color: gray;
}

/* Incoming arrow pseudo-elements */
.arrow.incoming::after {
  content: '';
  position: absolute;
  right: -30px; /* Adjust as needed */
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 30px solid green;
}

/* Outgoing arrow pseudo-elements */
.arrow.outgoing::before {
  content: '';
  position: absolute;
  left: -30px; /* Adjust as needed */
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 30px solid gray;
}

.amount {
  font-weight: bold;
}

.floew {
  display: flex;
  align-items: center;
  margin: 20px;
  position: relative;
}

.fundss {
  width: 450px;
  height: 60px;
  background-color: #333;
  position: relative;
}

.head-right,
.head-left {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
}

.head-right {
  border-left: 70px solid #333;
}

.head-left {
  border-right: 70px solid #333;
}

.lebel {
  /* position: absolute; */
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lebel-right {
  /* left: 30px; */
}

.lebel-left {
  /* right: 30px; */
}

.up .fundss {
  background-color: green;
}

.up .head-right {
  border-left-color: green;
}
